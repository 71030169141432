// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add Custom Checkout CSS Here */

.customerEmail-container .form-field:has(#shouldSubscribe) {
  display: none !important;
}

.payment-step-notice {
  text-align: center;
}


/* Format Shipping Method Display */

.shippingOption-desc span {
  display: block;
  font-size: 12px;
}


/* Best Value Shipping Banner */

#best-value-banner-desktop {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 0.75rem;
}

.best-value-banner-text {
  --r: .5em; /* control the ribbon shape */

  padding-inline: calc(var(--r) + .3em);
  clip-path: polygon(0 0,100% 0,calc(100% - var(--r)) 50%,100% 100%,0 100%, var(--r) 50%);
  width: fit-content;

  background-color: #d47125;
  color: #ffffff;
  margin: 0;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.best-value-banner-price {
  text-decoration: line-through;
  line-height: 1.5;
}

#best-value-banner-mobile {
  display: none;
}

@media only screen and (max-width: 680px) {
  #best-value-banner-mobile {
    display: block;
  }

  #best-value-banner-desktop .best-value-banner-text {
    display: none;
  }
}

@media (min-width:968px) and (max-width:1261px) {
  #best-value-banner-mobile {
    display: block;
  }

  #best-value-banner-desktop .best-value-banner-text {
    display: none;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
