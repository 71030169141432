import { Consignment } from '@bigcommerce/checkout-sdk';
import { logger } from '../../utils';

const SHIPPING_BANNER_ID = 'best-value-banner'
const SHIPPING_BANNER_TEXT_CLASS = 'best-value-banner-text'
const SHIPPING_BANNER_PRICE_CLASS = 'best-value-banner-price'

export const addBestValueShippingBanner = (consignments: Consignment[]) => {
  logger('-- addBestValueShippingBanner');

  // TODO: multi shipping location - only if needed
  const consignment = consignments[0];

  if (consignment) {
    const { id: consignmentId, availableShippingOptions } = consignment
    const guaranteedGroundOpt = availableShippingOptions?.find(opt => opt.description.includes('Guaranteed Ground'))

    if (!guaranteedGroundOpt || guaranteedGroundOpt.cost === 0) return;

    const shippingLabel = document.querySelector(`label[for="shippingOptionRadio-${consignmentId}-${guaranteedGroundOpt.id}"]`)
    const hasShippingBanner = shippingLabel?.classList.contains('has-best-value-banner');

    if (hasShippingBanner) {
      updateStrikeThroughPrice(guaranteedGroundOpt.cost)
    } else {
      addBestValueBannerToDOM(shippingLabel, guaranteedGroundOpt.cost)
      addBestValueBannerToDOMMobile(shippingLabel)
    }
  }
};


function createBestValueBanner(price: number) {
  const banner = document.createElement('div');
  banner.id = SHIPPING_BANNER_ID + '-desktop';
  banner.innerHTML = generateBannerTextHTML() + generateStrikeThroughPriceHTML(price);
  return banner;
}

function generateBannerTextHTML() {
  const starSVG = `
    <?xml version="1.0" encoding="utf-8"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 26 28" fill="#ffffff">
      <path d="M0 10.109q0-0.578 0.875-0.719l7.844-1.141 3.516-7.109q0.297-0.641 0.766-0.641t0.766 0.641l3.516 7.109 7.844 1.141q0.875 0.141 0.875 0.719 0 0.344-0.406 0.75l-5.672 5.531 1.344 7.812q0.016 0.109 0.016 0.313 0 0.328-0.164 0.555t-0.477 0.227q-0.297 0-0.625-0.187l-7.016-3.687-7.016 3.687q-0.344 0.187-0.625 0.187-0.328 0-0.492-0.227t-0.164-0.555q0-0.094 0.031-0.313l1.344-7.812-5.688-5.531q-0.391-0.422-0.391-0.75z"></path>
    </svg>
  `

  return `<p class="${SHIPPING_BANNER_TEXT_CLASS}">${starSVG} <span>Best Value</span> ${starSVG}</p>`
}

function generateStrikeThroughPriceHTML(price: number) {
  return `<span class="${SHIPPING_BANNER_PRICE_CLASS}">$${generateStrikeThroughPrice(price)}</span>`
}

function generateStrikeThroughPrice(price: number) {
  return (price * 2).toFixed(2);
}

function updateStrikeThroughPrice(price: number) {
  const banner = document.getElementById(SHIPPING_BANNER_ID);
  const bannerPrice = banner?.querySelector(`.${SHIPPING_BANNER_PRICE_CLASS}`);

  if (bannerPrice) {
    bannerPrice.textContent = generateStrikeThroughPrice(price);
  }
}

function addBestValueBannerToDOM(scope: Element | null, price: number) {
  scope?.classList.add('has-best-value-banner');
  const desc = scope?.querySelector('.shippingOption-desc');
  const bannerHTML = createBestValueBanner(price);

  desc?.after(bannerHTML);
}

/**
 * Adds banner text html to DOM for mobile breakpoints
 */
function addBestValueBannerToDOMMobile(scope: Element | null) {
  scope?.classList.add('has-best-value-banner');

  const shippingOption = scope?.querySelector('.shippingOption');

  const banner = document.createElement('div');
  banner.id = SHIPPING_BANNER_ID + '-mobile';
  banner.innerHTML = generateBannerTextHTML();

  shippingOption?.before(banner);
}