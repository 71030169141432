export const formatShippingMethodDisplay = () => {
  const el = document.querySelectorAll('.shippingOptionLabel');

  el.forEach((el) => {
    const descEl = el.querySelector('.shippingOption-desc');
    const description = descEl?.textContent;

    if (!description || !description.includes('(')) return;

    const newDesc =
      description
        .trim()
        .replace('(', '')
        .replace(')', '')
        .replace('Delivered', '<span>Delivered') + '</span>';

    if (
      newDesc.includes('Economy Shipping') &&
      newDesc.includes('<span>Delivered')
    ) {
      // return (descEl.innerHTML = newDesc.split('<span>Delivered')[0]);
    }

    descEl.innerHTML = newDesc;
  });
};
