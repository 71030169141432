import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryMobileMountedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MOBILE_MOUNTED,
  handler: ({ detail }) => {
    logger('Cart Summary Mobile Mounted');
    console.log(detail);
  },
};
