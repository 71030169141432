import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const paymentMethodSelectedButAlreadyActiveHandler = async ({
  detail,
}: EbizioEvent) => {
  logger('Payment Method Selected But Already Active');
  console.log(detail);

};

export const paymentMethodSelectedButAlreadyActiveConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_METHOD_SELECTED_BUT_ALREADY_ACTIVE,
  handler: paymentMethodSelectedButAlreadyActiveHandler,
};
