import { addBestValueShippingBanner } from '../custom/add-best-value-shipping-banner';
import { formatShippingMethodDisplay } from '../custom/format-shipping-method-display';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const shippingMethodSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_METHOD_SELECTED,
  handler: ({ detail }) => {
    logger('Shipping Method Selected');
    console.log(detail);

    formatShippingMethodDisplay();

    if (detail.consignments !== null && detail.consignments.length > 0) {
      addBestValueShippingBanner(detail.consignments);
    }
  },
};
