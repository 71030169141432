declare global {
  interface Window {
    EbizioEventManager: {
      pageType: string;
      checkoutId: string;
      gqlToken: string;
    };
  }
}

import type { EbizioEventConfig, EbizioOrderEventConfig } from './types';
import { CHECKOUT_CONTAINER } from './constants';
import {
  billingStepLoadingConfig,
  cartSummaryDesktopMountedConfig,
  cartSummaryMobileMountedConfig,
  cartSummaryModalOpenedConfig,
  checkoutLoadedConfig,
  couponsChangeddConfig,
  customerSignedInConfig,
  customerSignedOutConfig,
  customerStepLoadingConfig,
  giftCertificatesChangedConfig,
  orderConfirmationLoadedConfig,
  paymentMethodLoadedConfig,
  paymentMethodSelectedConfig,
  paymentMethodSelectedButAlreadyActiveConfig,
  paymentStepLoadingConfig,
  paymentStepSubmittedConfig,
  savedShippingAddressChangedConfig,
  savedShippingAddressDropdownOpenedConfig,
  savedBillingAddressChangedConfig,
  savedBillingAddressDropdownOpenedConfig,
  shippingMethodSelectedConfig,
  shippingStepLoadingConfig,
  shippingStepSubmittedConfig,
  storeCreditToggledConfig,
  useNewShippingAddressSelectedConfig,
  useNewBillingAddressSelectedConfig,
} from './events';

import './custom/styles';

/**
 * Configuration
 */
export const ebizioEventConfig: (EbizioEventConfig | EbizioOrderEventConfig)[] =
  [
    // global
    checkoutLoadedConfig,
    couponsChangeddConfig,
    giftCertificatesChangedConfig,
    cartSummaryDesktopMountedConfig,
    cartSummaryMobileMountedConfig,
    cartSummaryModalOpenedConfig,
    // customer step
    customerSignedInConfig,
    customerSignedOutConfig,
    customerStepLoadingConfig,
    // shipping step
    shippingStepLoadingConfig,
    shippingStepSubmittedConfig,
    shippingMethodSelectedConfig,
    savedShippingAddressChangedConfig,
    savedShippingAddressDropdownOpenedConfig,
    useNewShippingAddressSelectedConfig,
    // billing step
    billingStepLoadingConfig,
    savedBillingAddressChangedConfig,
    savedBillingAddressDropdownOpenedConfig,
    useNewBillingAddressSelectedConfig,
    // payment step
    paymentStepLoadingConfig,
    paymentStepSubmittedConfig,
    paymentMethodSelectedConfig,
    paymentMethodSelectedButAlreadyActiveConfig,
    paymentMethodLoadedConfig,
    storeCreditToggledConfig,
    // order confirmation
    orderConfirmationLoadedConfig,
  ];

/**
 * Setup Listeners
 */
ebizioEventConfig.forEach((config) => {
  if (CHECKOUT_CONTAINER) {
    CHECKOUT_CONTAINER.addEventListener(
      config.event,
      config.handler as EventListener,
    );
  }
});
