import { lang, logger } from '../../utils';

export const addPaymentStepNotice = () => {
  logger('-- addPaymentStepNotice');

  if (document.getElementById('payment-step-notice')) return;

  const paymentStepContainer = document.querySelector(
    '.checkout-step--payment .checkout-form',
  );

  const notice = lang('payment_step_notice');

  const p = document.createElement('p');
  p.id = 'payment-step-notice';
  p.className = 'payment-step-notice';
  p.innerText = notice;

  paymentStepContainer?.append(p);
};
