import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const savedShippingAddressChangedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SAVED_SHIPPING_ADDRESS_CHANGED,
  handler: ({ detail }) => {
    logger('Saved Shipping Address Changed');
    console.log(detail);
  },
};
