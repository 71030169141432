import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const useNewBillingAddressSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.USE_NEW_BILLING_ADDRESS_SELECTED,
  handler: ({ detail }) => {
    logger('Use New Billing Address Selected');
    console.log(detail);
  },
};
